.button {
    @include unselectable;

    position: relative;
    display: inline-block;
    height: 40px;
    padding: 0 32px;
    font-family: inherit;
    font-size: 15px;
    font-weight: 700;
    line-height: 40px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    background: $color-link;
    border: none;
    border-radius: $border-radius;
    outline: none;
    box-shadow: $box-shadow-button;
    transition: all 0.15s ease;
    appearance: none;
    &:hover {
        text-decoration: none;
        background-color: lighten($color-link, 5);
        transform: translateY(-1px);
    }
    &:disabled {
        cursor: default;
        opacity: 0.8;
        transform: translateY(-1px);
    }
    &.white {
        // color: red;
        background: inherit;
        border: solid 1px;
    }
}
.buttonFull {
    width: 100%;
    text-align: center;
}
