





































@import '@design';

.wrapper {
    position: relative;
    z-index: 1;

    @include tablet {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.wrapper + .wrapper {
    margin-top: 100px;

    @include tablet {
        margin-top: 175px;
    }
}
.typeSide {
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
    }
}
.typeSide > div:last-child {
    @include mobile {
        margin-top: 55px;
    }
}
.positionRight {
    flex-direction: row-reverse;
}
.positionLeft > div:first-child {
    @include tablet {
        margin-right: 55px;
    }
}
.positionRight > div:first-child {
    @include tablet {
        margin-left: 55px;
    }
}
.positionCenter > div:last-child {
    margin-top: 35px;

    @include tablet {
        margin-top: 55px;
    }
}
