













































.expand-enter-active,
.expand-leave-active {
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.3, 0, 0, 1);
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
