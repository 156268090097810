



























@import '@design';

.typography {
    font-family: $font-family-heading;
    font-size: 47px;
    line-height: 1.085;
    font-weight: 700;
    letter-spacing: 0;
    @include mobile {
        font-size: 32px;
        line-height: 1.125;
        letter-spacing: 0.004em;
    }
    @include tablet-only {
        font-size: 40px;
        line-height: 1.1;
    }
}
.titlePositionCenter {
    text-align: center;
}
.titlePositionRight {
    text-align: right;
}
