@import 'colors';
@import 'mixins';
@import 'screens';
@import 'fonts';
@import 'sizes';
@import 'durations';
@import 'box-shadows';
@import 'border-radius';
@import 'padding';

:export {
    global-mobile: $tablet - 1px;
    global-tablet: $tablet;
    global-desktop: $desktop;
    global-widescreen: $widescreen;
    global-color-error: $color-error;
    global-color: $color-purple;
    global-subscription-color: $color-subscription;
}
