




















.reveal li {
    opacity: 1;
}
.boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin-top: 40px;
    list-style: none;
    li {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        margin: 10px;
        color: #ccc;
        border: 3px solid #171717;
        border-radius: 8px;
        opacity: 0;
        transition: opacity 1s;
        will-change: opacity;
        &:nth-child(1) {
            transition-delay: 0.5s;
        }
        &:nth-child(2) {
            transition-delay: 0.65s;
        }
        &:nth-child(3) {
            transition-delay: 0.8s;
        }
        &:nth-child(4) {
            transition-delay: 0.95s;
        }
        &:nth-child(5) {
            transition-delay: 1.1s;
        }
    }
    h3 {
        margin-top: 0.125em;
        font-size: 19px;
        font-weight: 600;
        line-height: 1.21053;
        letter-spacing: 0.012em;
    }
    p {
        display: block;
        padding: 0 20px;
        margin-top: 0.5em;
        text-align: center;
    }
    figure {
        margin: 10px;
        img {
            width: 55px;
            height: 55px;
            opacity: 0.6;
        }
    }
}
