
























































@import '@design';

.modalMask {
    // border: 5px solid yellow;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(black, 0.6);
    transition: opacity 0.3s ease;
}

.modalContainer {
    // border: 5px solid blue;
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    width: 60%;
    @include mobile {
        width: 100%;
        height: 100%;
    }
    background-color: $color-background-dark;
    border-radius: $border-radius;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modalHeader {
    // border: 5px solid green;
    max-height: 20%;
    position: sticky;
    overflow-y: auto;
    border-bottom: solid 1px #d6d6d6;
    font-size: 1.2em;
    font-weight: bold;
    padding: 20px 50px 20px 50px;
}

.modalBody {
    // border: 5px solid red;
    overflow-y: auto;
    max-height: 80%;
    padding: 20px 50px 45px 50px;
}

.modalClose {
    // border: 5px solid purple;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9998;
    .modalCloseButton {
        display: inline-block;
        padding: 21px;
        margin: -24px;
        cursor: pointer;
    }
    .modalCloseCross {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        font-size: 0;
        cursor: pointer;
        &::after,
        &::before {
            position: absolute;
            top: 11px;
            right: 0;
            left: 0;
            height: 2px;
            content: '';
            background: currentColor;
            border-radius: 3px;
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}

.transitionSlideEnterActive,
.transitionSlideLeaveActive {
    transition: all 0.3s ease;
}
.transitionSlideEnter,
.transitionSlideLeaveTo {
    transform: translate3d(0, 100%, 0);
}
