











@import '@design';

.row {
    position: relative;
    z-index: 1;
    display: flex;

    @include mobile {
        display: block;
        margin-bottom: 40px;
        border-top: none;
    }

    @include tablet {
        &:not(:first-child) {
            border-top: 1px solid #444;
        }
    }

    &::before,
    &::after {
        display: table;
        content: ' ';
    }
}
.rowHeader {
    font-family: $font-family-heading;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    float: left;
    width: 27%;
    min-height: 2px;
    padding: 0;
    padding: 35px 15px 32px 0;
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.16667;
    letter-spacing: 0.009em;

    @include mobile {
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        padding: 0;
        padding-bottom: 10px;
        margin-bottom: 17px;
        border-bottom: 1px solid #d6d6d6;
    }
}
.rowCell {
    flex: 1;
    padding: 35px 0 32px;

    @include mobile {
        width: 100%;
        padding: 20px 0;
    }
    strong {
        font-weight: 600;
    }
}
