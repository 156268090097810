// Texts
$color-text: #fff;
$color-text-second: #82879e;
$color-text-soft: #d8dfea;

// Colors
$color-purple: #6249ff;
$color-subscription: #ffc400;
$color-pink: #ee42a1;
$color-gold: #d9b69e;
$color-red: #e0475f;

// Links
$color-link: $color-purple;

// Status
$color-error: $color-red;
$color-success: #4effc0;

// Backgrounds
$color-background: #f5f6fa;
$color-background-soft: #fafafa;
$color-background-dark: #191919;
$color-background-dark-soft: #262626;
$color-background-dark-extra-soft: #666;
$color-background-app: #000;
$color-background-input: rgba(white, 0.05);
