























































































































@import '@design';

.button {
    @include mobile {
        width: 100%;
    }
    span {
        display: block;
        opacity: 1;
        transition: all 0.3s ease-in-out 0s;
        transform: scale(1);
        &.buttonDisappeared {
            opacity: 0;
            transition: all 0.3s ease-in-out 0s;
            transform: scale(0.5);
        }
    }
}
.errorGlobal {
    text-align: center;
}
.spinner {
    padding: 5px;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5em;

    @include mobile {
        flex-direction: column-reverse;
    }
    .back {
        display: flex;
        align-items: center;

        @include mobile {
            padding-top: 1.5em;
            text-align: center;
        }
        .iconBack {
            width: 10px;
            height: 10px;
            margin-right: 0.25em;
            color: currentColor;
            fill: currentColor;
        }
    }
}

.asterisk {
    text-align: end;
    font-size: 12px;
    margin-top: 5px;
    color: $color-text-soft;
}
