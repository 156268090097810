





























@import '@design';

.noLive {
    margin-bottom: 20px;
    color: white;
    text-align: center;
    background: black;
    h2 {
        @include font-heading;

        padding: 20px;
        font-size: 25px;
    }
}
.nextEpreuves {
    margin-bottom: 20px;
    text-align: center;
    background: $color-background-soft;
    h3 {
        @include font-heading;

        padding: 20px 20px 0;
        margin-bottom: 10px;
        font-size: 25px;
    }
    li {
        padding: 10px 20px;
        &:not(:first-child) {
            border-top: 1px solid white;
        }
    }
}
