


















































@import '@design';

.wrapper {
    margin: 0;
    display: flex;
    justify-content: flex-end;

    @include tablet {
        display: none;
    }
}
.rootLink {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transform: scale(0.8);
    h2 {
        top: 25px;
        left: 10px;
        font-size: 0;
        &,
        &::after,
        &::before {
            position: absolute;
            width: 30px;
            height: 4px;
            background: $color-text;
            border-radius: 3px;
        }
        &::after,
        &::before {
            left: 0;
            content: '';
        }
        &::before {
            top: -9px;
        }
        &::after {
            top: 9px;
        }
    }
}
.popup {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    text-align: left;
    pointer-events: none;
    perspective: 2000px;
}
.popupContainer {
    position: relative;
    flex-grow: 1;
    padding-top: 20px;
    overflow: hidden;
    font-size: 15px;
    line-height: 40px;
    white-space: nowrap;
    background: $color-background-dark;
    border-radius: 4px;
    box-shadow: $box-shadow-popup;
    opacity: 0;
    transition-duration: 0.25s;
    transition-property: transform, opacity;
    transform: scale(0.95);
    transform-origin: 100% 0;
    will-change: transform, opacity;
    &.open {
        pointer-events: auto;
        opacity: 1;
        transform: none;
    }
    h4 {
        margin: -5px 0 0 30px;
        font-size: 14px;
        font-weight: 600;
        color: #2d2d2d;
        text-transform: uppercase;
        letter-spacing: 0.025em;
    }
}
.popupCloseButton {
    position: absolute;
    top: -4px;
    right: 0;
    width: 51px;
    height: 51px;
    font-size: 0;
    cursor: pointer;
    &::after,
    &::before {
        position: absolute;
        top: 24px;
        right: 14px;
        left: 14px;
        height: 4px;
        content: '';
        background: $color-background-dark-extra-soft;
        border-radius: 3px;
        transition: background 0.1s;
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 10px 15px;

    ul {
        flex-grow: 3;
    }
    a {
        display: block;
        min-width: 100px;
        padding: 0 20px;
        color: $color-text;
        &:hover {
            text-decoration: none;
        }
    }
}
