























@import '@design';

.header {
    position: relative;
    margin-top: -90px;
    padding-top: 90px;
    background: linear-gradient(#1a1a1f, black);
}
.headerContent {
    max-width: 720px;
    padding: 100px 0 70px;
    margin: 0 auto;
    text-align: center;

    @include tablet {
        padding: 160px 0 140px;
    }
}
.headerTitle {
    margin: 0 0 20px;
    font-weight: 500;
}
