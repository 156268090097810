







































































@import '@design';

.subtitle {
    font-size: 16px;
    display: inline-block;
    font-weight: 600;
    width: 100%;
    p {
        margin-top: 1rem;
    }
}

.badge {
    width: 130px;
    margin-bottom: 0.5rem;
}
.badges {
    display: flex;

    // flex-direction: column;
    justify-content: center;
    text-align: center;
}
