











































@import '@design';

.app {
    flex-grow: 1;
}
.loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.logo {
    height: 50px;
    margin-top: -90px;
    opacity: 0.8;
}
