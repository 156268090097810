


















@import '@design';

.wrapper {
    background: #121217;
    li {
        &:not(:first-child) {
            border-top: 1px solid #313131;
        }
    }
}
