



























@import '@design';

.typography {
    font-family: $font-family-heading;
    font-size: 24px;
    line-height: 1.33349;
    font-weight: 400;
    letter-spacing: 0.009em;
    @include mobile {
        font-size: 19px;
        line-height: 1.42;
        letter-spacing: 0.012em;
    }
    @include tablet-only {
        font-size: 21px;
        line-height: 1.38;
        letter-spacing: 0.011em;
    }
}
h3 + .typography {
    margin-top: 1.53em;
    @include mobile {
        margin-top: 1.3em;
    }
    @include tablet-only {
        margin-top: 1.18em;
    }
}
.titlePositionCenter {
    text-align: center;
}
.titlePositionRight {
    text-align: right;
}
