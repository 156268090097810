



























@import '@design';

.typeSimple {
    width: 100%;
}
.typeOverlay {
    img {
        width: 100%;
        @include tablet {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}
