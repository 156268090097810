.form {
    button,
    input,
    optgroup,
    select,
    textarea {
        appearance: none;
    }
    h2 {
        @include font-heading;

        margin-bottom: 10px;
        font-size: 16px;
    }
    h2 + h3 {
        margin-top: -10px;
        margin-bottom: 10px;
        font-size: 14px;
        color: $color-text-second;
    }
    fieldset {
        position: relative;
        &:not(:first-child) {
            padding-top: 40px;
        }
    }
    .fields {
        display: flex;
        flex-wrap: wrap;
        margin: -$padding-field;
        zoom: 1;
    }
    .field {
        box-sizing: border-box;
        width: 100%;
        padding: $padding-field;
        &.fieldHalf {
            width: 50%;
        }
        &.fieldQuarter {
            width: 25%;
        }
        .fieldWrapper {
            position: relative;
        }
    }
    .input {
        input,
        textarea,
        select {
            color: white;
            background: transparent;
            border: 1px solid #fff6;
            box-shadow: none;
            &:focus {
                border-color: white;
            }
        }

        box-sizing: border-box;
        display: block;
        width: 100%;
        min-height: 44px;
        padding: 8px 15px;
        font-size: 16px;
        color: currentColor;
        word-break: normal;
        background-color: $color-background-input;
        background-clip: padding-box;
        border-radius: $border-radius;
    }
    label + .input {
        padding: 18px 15px 8px;
    }
    .hasFocus,
    .hasValue {
        position: relative;
        .label {
            z-index: 2;
            font-size: 0.7em;
            cursor: default;
            transform: translateY(-7px);
        }
    }
    .hasFocus {
        label {
            color: $color-link;
        }
        .radios label {
            color: $color-text-soft;
        }
    }
    .hasFocus > input {
        border-color: $color-link;
    }
    .hasError {
        .input,
        .radios {
            box-shadow: 0 0 0 1px $color-error;
        }
        .radios {
            padding: $padding-field;
            border-radius: $border-radius;
        }
    }
}

::placeholder {
    color: $color-text-soft;
}
