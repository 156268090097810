




















































@import '@design';

.wrapper {
    display: flex;
    justify-content: center;
}

.list {
    list-style-type: square;
    width: fit-content;
    text-align: left;
}
