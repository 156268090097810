









































































@import '@design';

.wrapper {
    background-color: transparent;
    display: flex;
    justify-content: center;
}

.content {
    width: 692px;
    width: fit-content;
}

.headline {
    font-size: 12pt;
    font-weight: 800;
    line-height: 17pt;
    text-transform: uppercase;
}

.date {
    font-size: 13pt;
    line-height: 18pt;
    text-align: end;
    white-space: nowrap;
}

.right {
    align-self: center;
    margin-left: 8px;
    white-space: nowrap;
}
.rightText {
    padding: 7px 15px;
    font-size: 12pt;
    line-height: 15pt;
    font-weight: 800;
    color: $color-text;
    border-radius: $border-radius;
}

.asterisk {
    align-self: center;
    font-style: italic;
    font-size: 6pt;
    line-height: 7pt;
}
