@font-face {
    font-family: 'Alogo Display';
    font-weight: 100;
    src: url('~@assets/fonts/display-ultralight.woff2') format('woff2'),
        url('~@assets/fonts/display-ultralight.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 200;
    src: url('~@assets/fonts/display-thin.woff2') format('woff2'),
        url('~@assets/fonts/display-thin.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 300;
    src: url('~@assets/fonts/display-light.woff2') format('woff2'),
        url('~@assets/fonts/display-light.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 400;
    src: url('~@assets/fonts/display-regular.woff2') format('woff2'),
        url('~@assets/fonts/display-regular.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 500;
    src: url('~@assets/fonts/display-medium.woff2') format('woff2'),
        url('~@assets/fonts/display-medium.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 600;
    src: url('~@assets/fonts/display-semibold.woff2') format('woff2'),
        url('~@assets/fonts/display-semibold.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 700;
    src: url('~@assets/fonts/display-bold.woff2') format('woff2'),
        url('~@assets/fonts/display-bold.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 800;
    src: url('~@assets/fonts/display-heavy.woff2') format('woff2'),
        url('~@assets/fonts/display-heavy.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Display';
    font-weight: 900;
    src: url('~@assets/fonts/display-black.woff2') format('woff2'),
        url('~@assets/fonts/display-black.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Text';
    font-weight: 200;
    src: url('~@assets/fonts/text-light.woff2') format('woff2'), url('~@assets/fonts/text-light.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Text';
    font-weight: 400;
    src: url('~@assets/fonts/text-regular.woff2') format('woff2'),
        url('~@assets/fonts/text-regular.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Text';
    font-weight: 500;
    src: url('~@assets/fonts/text-medium.woff2') format('woff2'), url('~@assets/fonts/text-medium.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Text';
    font-weight: 600;
    src: url('~@assets/fonts/text-semibold.woff2') format('woff2'),
        url('~@assets/fonts/text-semibold.woff') format('woff');
}

@font-face {
    font-family: 'Alogo Text';
    font-weight: 700;
    src: url('~@assets/fonts/text-bold.woff2') format('woff2'), url('~@assets/fonts/text-bold.woff') format('woff');
}
