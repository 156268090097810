







































































.text {
    font-style: italic;
    margin-bottom: 30px;
    display: flex;
}

.role {
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
}

.horses {
    margin-top: 20px;
}

.img {
    border-radius: 10px;
    width: 100%;
}

h2 {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 5px;
}
