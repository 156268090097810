
































@import '@design';

.logos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px 30px;
    margin: 40px 0;
    gap: 14px 30px;

    @include tablet {
        grid-template-columns: repeat(4, 1fr);
        margin: 70px 0;
    }

    &.logosColumns3 {
        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &.logosColumns4 {
        @include tablet {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    figure {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            height: auto;
            transition: transform 100ms;
            transform: scale(0.8);
            background: white;
            border-radius: 3px;
            padding: 15px;
            &:hover {
                transform: scale(1);
            }
        }

        figcaption {
            text-align: center;
        }
    }
}
