
























































































































@import '@design';

.hero {
    overflow-x: hidden;
}

.endOfComplexity {
    margin-top: -90px;
}

.photos {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 30px 60px -12px rgba(0, 0, 0, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);

    &.logoAmerigo {
        width: 60%;
        margin: 50px auto;
    }
}

.founderPics {
    img:not(:first-child) {
        margin-top: 20px;
    }
}

.subtitle {
    font-weight: 800;
    margin-bottom: 20px;
    margin-top: 10px;
}
