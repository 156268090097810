












































































































@import '@design';

.heroImage {
    margin-top: 24px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
}

.subtitle {
    margin-top: 20px;
    margin-bottom: 50px;
}

// Partner
.partnerMedia {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    border-radius: 16px;

    @include tablet {
        height: 275px;
    }

    @include mobile {
        width: 100%;
        height: auto;
    }
    img {
        border-radius: 16px;
        width: 100%;
        transition: transform 100ms;
        transform: scale(0.8);
        background: white;
        &:hover {
            transform: scale(1);
        }
    }
}

.alogo {
    width: 75%;
    padding: 30px;
}
