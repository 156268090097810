



















@import '@design';

.sectionQuote {
    position: relative;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    border-radius: 4px;
    overflow: hidden;
    background: $color-background-dark;
    blockquote {
        display: block;
        position: relative;
        padding: 26px 30px 30px;
        @include tablet {
            padding: 36px 55px 55px;
        }
    }
    h2 {
        @include font-heading;
        font-size: 28px;
        margin: 0 0 20px;
    }
}
q {
    position: relative;
    font-style: italic;
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 20px;
    quotes: initial;
}
.sectionQuoteBlockBy {
    font-size: 17px;
    line-height: 28px;
    margin-top: 25px;
    span {
        display: block;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
    }
}
.sectionQuotePhoto {
    position: relative;
    width: 100%;
    min-height: 310px;
    background-size: cover;
    background-position: 50% 50%;
    img {
        width: 100%;
    }
}
