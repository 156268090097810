



























@import '@design';

.typography {
    position: relative;
    z-index: 2;
    font-family: $font-family-heading;
    line-height: 1;
    font-weight: 700;
    font-size: 120px;
    letter-spacing: -0.015em;
    @include mobile {
        font-size: 44px;
    }
    @include tablet-only {
        font-size: 80px;
    }
}
.titlePositionCenter {
    text-align: center;
}
.titlePositionRight {
    text-align: right;
}
