









































































































































































@import '@design';

$background-board: #121217;

// Board
.board {
    position: relative;
}

// Rider
.rider {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 15px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.65);
    background: $background-board;
    border-bottom: 1px solid #313131;

    @include mobile {
        padding: 10px;
    }
    .riderNumber {
        margin-right: 10px;
        font-weight: 700;
        color: $color-gold;
        text-align: center;
        span {
            display: flex;
            font-size: 8px;
            font-weight: 500;
            color: rgba(white, 0.5);
            text-transform: uppercase;
        }
    }
    .riderPeer {
        display: flex;
        flex: 1;
        flex-direction: column;
        font-size: 1.2em;
        font-weight: 600;
        color: white;
        span {
            margin-top: 5px;
            font-size: 0.75em;
            font-weight: 400;
        }
    }
    .riderCountry {
        padding: 6px 7px;
        color: white;
        border: 1px solid white;
        border-radius: 4px;
    }
}

// Obstacles
.obstacles {
    position: relative;
    width: 100%;
    height: 368px;
    background: $background-board;

    @include tablet {
        height: 441.6px;
    }

    @include desktop {
        height: 552px;
    }
}

// Details
.details {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.65);
    background: $background-board;
    border-top: 1px solid #313131;
    .detailsTimer,
    .detailsPenalities {
        display: flex;
        flex-direction: column;
        padding: 20px;
        font-size: 20px;
        line-height: 1;
        span {
            display: inline-block;
            margin-top: 3px;
            font-size: 1.6em;
            font-weight: 600;
            color: white;
        }
    }
    .detailsTimer {
        width: 30%;
        border-right: 1px solid #313131;
        .maxtimeExceeded {
            color: red;
        }
    }
    .detailsPenalities {
        width: 70%;
        .detailsPenalitiesObstacles,
        .detailsPenalitiesTime {
            margin-top: 5px;
            font-size: 14px;
        }
    }
}

// Statistics
.statistics {
    padding: 20px;
    color: rgba(255, 255, 255, 0.65);
    background: $background-board;
    border-top: 1px solid #313131;
    h3 {
        font-size: 20px;
        line-height: 1;
    }
    ul {
        display: flex;
        margin-top: 15px;
        li {
            padding: 5px 10px;
            color: white;
            border: 1px solid white;
            border-radius: $border-radius;
        }
    }
}

// Summary
.summary {
    display: flex;
    margin-top: 24px;
    margin-bottom: 58px;
    .summaryContent {
        flex: 1;
        margin-right: 10px;
        h2 {
            padding-bottom: 6px;
            margin: 0;
            font-family: $font-family-heading;
            font-size: 36px;
            font-weight: 800;
            line-height: 36px;
            word-wrap: break-word;

            @include mobile {
                font-size: 30px;
            }
        }
        h3 {
            font-size: 12px;
            font-weight: 800;
            line-height: 16px;
            text-transform: uppercase;
        }
    }
    .summaryLogo {
        width: 64px;
        height: 64px;
        overflow: hidden;
        background: #d8d8d8;
        border: 2px solid #fff;
        border-radius: 50%;
        img {
            width: 64px;
            height: 64px;
            object-fit: cover;
        }
    }
    .summaryContentMaxtime {
        display: inline-block;
        padding: 0 6px;
        border: 1px solid rgba($color-text, 0.2);
        border-radius: 4px;
        span {
            font-weight: 600;
        }
    }
}
