a {
    color: $color-link;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.router-link-active a {
    color: rgba(white, 0.6) !important;
}
