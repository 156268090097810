.container,
.containerFluid,
.containerSm,
.containerLg,
.containerWide,
.containerXl {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}
.container,
.containerLg {
    max-width: 1040px;
}
.containerWide,
.containerXl {
    max-width: 1160px;
}
.containerSm {
    max-width: 652px;
}
