




































































































@import '@design';

.intro {
    * > * & :not(:last-child) {
        margin-bottom: 30px;
    }
}

.rounded {
    border-radius: $border-radius-large;
}

.w-100 {
    width: 100%;
}

.health-buttons {
    * > * & :first-child {
        margin-right: 10px;
    }
}

.premiumBadge {
    width: fit-content;
    margin-bottom: 10px;
    margin-left: 0px;
}

.technologyInjuries {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 540px;

        @include mobile {
            height: 380px;
        }
    }
}
