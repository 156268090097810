$tablet: 769px;
$desktop: 1000px;
$widescreen: 1192px;

@mixin mobile {
    @media screen and (max-width: $tablet - 1px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
        @content;
    }
}

@mixin touch {
    @media screen and (max-width: $desktop - 1px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin desktop-only {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
        @content;
    }
}

@mixin widescreen {
    @media screen and (min-width: $widescreen) {
        @content;
    }
}
