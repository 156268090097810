






































@import '@design';

.subsectionWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    &.inverted {
        justify-content: flex-end;
    }
}
.sectionSubsection {
    padding-top: 150px;
}
.sectionGraphics {
    padding-top: 100px;
}
.sectionParagraph {
    position: relative;
    z-index: 1;
    &.paragraphNormal {
        width: 45%;
    }
}
.subheadline {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.08365;
    letter-spacing: 0;
}
.intro {
    margin-top: 1.5em;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.33349;
    letter-spacing: 0.009em;
}
