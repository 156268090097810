

















































































































































@import '@design';

.spaceY {
    * > * & :not(:first-child) {
        margin-top: 30px;
    }
}

.rounded {
    border-radius: $border-radius-large;
}

.w-100 {
    width: 100%;
}

.polarLogoAndEquine {
    display: grid;
    justify-content: center;
    justify-items: center;

    * > * & :not(:first-child) {
        margin-top: 50px;
    }

    .poweredByPolar {
        display: inherit;
        justify-content: inherit;
        justify-items: inherit;

        * > * & :not(:first-child) {
            margin-top: 5px;
        }

        .polarLogo {
            width: 200%;
        }
    }
}

.mockupWebHeartRate,
.equineInHands {
    width: 100%;
    border-radius: $border-radius-large;
}

.stepList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 30px;

    @include mobile {
        li:not(:first-child) {
            margin-top: 30px;
        }
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        width: 300px;
        height: 630px;
        padding: 50px;
        margin: 10px;
        background: $color-background-dark;
        border-radius: 50px;

        img {
            width: 100%;
        }

        p {
            margin-top: 15px;
            text-align: center;
        }
    }
}
