:root {
    height: 100%;
    overflow-x: hidden;
}
* {
    box-sizing: border-box;
}
body {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    min-height: 100%;
    font-family: $font-family;
    font-size: 14px;
    line-height: 1.43;
    color: $color-text;
    background-color: $color-background-app;
}
html,
body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscales;
}
img,
svg {
    display: block;
}
ol,
ul {
    list-style: none;
}
p + *,
ul + *,
ol + * {
    margin-top: 0.8em;
}
button,
input,
optgroup,
select,
textarea {
    border: 0;
    outline: none;
}
.is-exact-active {
    opacity: 1 !important;
}

.revealableText {
    display: inline-block;
}
.revealableImage {
    display: block;
}
.revealableText,
.revealableImage {
    @include animateText;
}
.reveal {
    .revealableText,
    .revealableImage {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}
.more::after {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    content: '';
    border: solid currentColor;
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
}
.headline {
    span {
        @include font-heading;

        display: block;
        font-size: 64px;
        font-weight: 600;
        line-height: 1.0625;
        letter-spacing: -0.009em;
    }
    .sectionHeadline {
        color: #cfcfcf;
    }
}
.intro {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.47059;
    letter-spacing: -0.022em;
}
.offset {
    width: 58.33333%;
    margin-left: 8.33333%;
}
.figureWrapper {
    position: relative;
    z-index: 1;
    margin-top: 75px;
    margin-bottom: 75px;
}
.headlineSuper {
    @include font-heading;

    width: 83.33333%;
    margin: 0 auto;
    font-size: 80px;
    line-height: 1.05;
    letter-spacing: -0.015em;
}
.commonPageTitle {
    font-size: 45px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -0.01em;

    @include tablet {
        font-size: 53px;
        line-height: 68px;
    }
}
.commonSectionTitle {
    font-size: 34px;
    font-weight: 400;
    line-height: 44px;

    @include tablet {
        margin: 25px 0 18px;
        font-size: 42px;
        line-height: 52px;
    }
}
.commonIntroText {
    font-size: 21px;
    font-weight: 300;
    line-height: 32px;

    @include tablet {
        font-size: 24px;
        line-height: 36px;
    }
}
.commonMediumBodyText {
    font-size: 19px;
    font-weight: 400;
    line-height: 32px;
}
.commonTextSource {
    display: block;
    padding-top: 5px;
    font-size: 13px;
}
.commonBackgroundLight {
    background-color: $color-background-dark;
}
.commonBackgroundDark {
    background-color: black;
}
.commonSection {
    padding: 140px 0 100px;
}
