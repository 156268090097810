





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '@design';

.page {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.47059;
    letter-spacing: -0.022em;
}

.logo {
    width: 500px;
    margin-top: -90px;
}

.spaceX {
    * > * & :not(:first-child) {
        margin-top: 30px;
    }
}

// Hero
.heroIntroParagraphs {
    @include mobile {
        text-align: left;
    }
}
.heroDetails {
    position: absolute;
    font-family: $font-family-heading;
    font-size: 25px;
    font-weight: 300;
    line-height: 1;
    text-align: left;

    @include mobile {
        font-size: 15px;
    }

    @include tablet-only {
        font-size: 20px;
    }
    &::after {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 5px;
        content: '';
        background: $color-link;
        border-radius: $border-radius-round;
        box-shadow: 0 0 0 5px rgba(98, 73, 255, 0.25);
        animation: detailsPulse 2s infinite ease;

        @include mobile {
            width: 4px;
            height: 4px;
        }

        @include tablet-only {
            width: 7px;
            height: 7px;
        }
    }
    &.heroDetailsGps {
        top: 10%;
        left: 105%;

        @include mobile {
            left: 102%;
        }
    }
    &.heroDetailsGyro {
        top: 50%;
        right: 100%;
        text-align: right;
        &::after {
            right: 0;
        }
    }
    &.heroDetailsSize {
        bottom: 15%;
        left: 83%;

        > span {
            font-size: 12px;

            @include mobile {
                font-size: 10px;
            }
        }
    }
}
.heroImage {
    position: relative;
    width: 501px;
    height: 784px;
    margin-top: 24px;
    background-image: url('~@assets/images/move-hero-large.png');
    background-repeat: no-repeat;
    background-size: 501px 784px;

    @include mobile {
        width: 207px;
        height: 324px;
        margin-top: 62px;
        margin-bottom: 15px;
        background-image: url('~@assets/images/move-hero-small.png');
        background-size: 207px 324px;
    }

    @include tablet-only {
        width: 302px;
        height: 472px;
        margin-top: 36px;
        margin-bottom: 10px;
        background-image: url('~@assets/images/move-hero-medium.png');
        background-size: 302px 472px;
    }
}
.heroPromo {
    display: inline-block;
    padding: 3px 10px;
    text-align: center;
    border: 2px solid rgba($color-text, 0.25);
    border-radius: $border-radius;

    @include mobile {
        margin-top: 35px;
    }
}

// Features
.features {
    position: relative;
    width: 100%;
}
.featuresCurve {
    position: relative;
    height: 900px;

    @include mobile {
        height: 350px;
    }
}
.featuresHeadline {
    position: absolute;
    top: 145px;
    z-index: 2;
    text-shadow: 0 15px 50px black;

    @include mobile {
        position: relative;
        top: auto;
    }
}
.featuresMedia {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(#000 0%, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0.9) 80%, #000),
        url('~@assets/vector/grid-white.svg');

    @include mobile {
        bottom: auto;
    }
}
.featuresTextOverview {
    position: absolute;
    font-family: $font-family-heading;
    font-size: 25px;
    font-weight: 300;
    color: $color-text;

    @include mobile {
        font-size: 20px;
    }
    span {
        display: block;
        font-size: 20px;
        font-weight: 200;
        letter-spacing: 0.009em;

        @include mobile {
            font-size: 15px;
        }
    }
    &.featuresTextOverviewHorsetrack {
        @include mobile {
            top: 100%;
        }

        @include tablet-only {
            top: 100%;
        }

        @include desktop {
            top: 100%;
        }
    }
    &.featuresTextOverviewHeight {
        @include mobile {
            top: 10%;
            right: auto;
            left: 0;
        }

        @include tablet-only {
            top: 25%;
            right: 3%;
            width: 290px;
        }

        @include desktop {
            top: 25%;
            left: 80%;
            text-align: right;
        }
    }
    &.featuresTextOverviewVelocity {
        top: 30%;
        right: 60%;
        text-align: right;

        @include mobile {
            top: 35%;
            right: 0;
        }
    }
    &.featuresTextOverviewAngle {
        right: 75%;
        bottom: 20%;
        text-align: right;

        @include mobile {
            top: 50%;
            right: auto;
            left: 0;
            text-align: left;
        }
    }
    &.featuresTextOverviewFrappe {
        top: 110%;
        left: 23%;

        @include tablet-only {
            top: 100%;
            left: 10%;
        }

        @include mobile {
            top: 80%;
            right: 0;
            left: auto;
            text-align: right;
        }
    }
}
.featuresParagraph {
    @include tablet {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
    }
}
.technology {
    margin-top: 90px;

    @include mobile {
        margin-top: 60px;
    }

    @include tablet-only {
        margin-top: 40px;
    }
}

.trustedFeiTexts:nth-child(odd) {
    margin-bottom: 30px;
}

.animationHorseTrajectory {
    margin-bottom: 80px;
}

// Health
.health-buttons {
    * > * & :first-child {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

// Heart rate
.polarButtons {
    * > * & :not(:first-child) {
        margin-left: 10px;
    }
}

.polarImage,
.subscriptionImage,
.partnershipImage,
.trustedFei,
.healthImage {
    width: 100%;
    border-radius: $border-radius-large;
}

// Accessibility
.accessibilityAppMockup img {
    width: 100%;
    margin: 50px 0;
}
.accessibilityMovePush {
    transform: rotate(180deg);
}
.badgesAppStores {
    margin-top: 45px;
}
.badgeWebapp {
    margin-top: 45px;
}
.teamWorking {
    margin-top: 200px;

    @include mobile {
        margin-top: 150px;
    }
}
.teamWorkingMedia {
    width: 100%;
    border-radius: $border-radius-large;
}

// Universal
.universalList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        padding: 50px;
        margin: 10px;
        background: $color-background-dark;
        border-radius: 50px;

        @include mobile {
            width: calc(50% - 10px);
            padding: 15px;
            margin: 5px;
            border-radius: 18px;
        }
        img {
            width: 4rem;
            height: 4rem;

            @include mobile {
                width: 3rem;
                height: 3rem;
            }
        }
        span {
            display: block;
            margin-top: 15px;
            font-family: $font-family-heading;
            font-size: 20px;
            font-weight: 700;

            @include mobile {
                font-size: 18px;
            }
        }
        p {
            text-align: center;

            @include mobile {
                font-size: 14px;
            }
        }
    }
}
.followupMedia {
    img {
        width: 100%;
        border-radius: $border-radius-large;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }
}

// Animations
@keyframes detailsPulse {
    0% {
        box-shadow: 0 0 0 0 rgba($color-link, 0.7);
    }
    70% {
        box-shadow: 0 0 0 20px rgba($color-link, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($color-link, 0);
    }
}

// Technology
img.technologyPuce,
img.technologyPuceMobile {
    border-radius: 18px;
}
img.technologyPuce {
    @include mobile {
        display: none;
    }
}
img.technologyPuceMobile {
    border-radius: 18px;

    @include tablet {
        display: none;
    }
}
.technologyInjuries {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 540px;

        @include mobile {
            height: 380px;
        }
    }
}

// ambasadors
.splideImg {
    margin-bottom: 10px;
    transition: transform 0.2s;
    width: 100%;
    border-radius: 10px;

    &.ambassadorImg {
        opacity: 0.6;
    }

    &.testimonialImg {
        opacity: 0.45;
        filter: blur(2px);
    }

    &:hover {
        transform: scale(1.05);
    }
}

.ambassadorsContainer,
.introContainer {
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-bottom: 40px;
}

.ambassadorsName {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 42px;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
}

.ambassadorsFlag {
    font-size: 35px;
}

.ambassadorsClickText {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

// testimonials
.testimonialContainer {
    position: relative;
    text-align: start;
    margin-bottom: 40px;
}

.testimonialText {
    position: absolute;
    top: 10px;
    padding: 0 10px;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
    line-height: 28px;
}

.testimonialTitle {
    position: absolute;
    padding: 0 8px;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .testimonialRole {
        font-size: 10px;
    }

    .testimonialName {
        font-size: 25px;
    }
}

// media
.media {
    margin-top: 50px !important;
}

.mediaTitle {
    @include mobile {
        margin-bottom: -20px;
        text-align: left !important;
    }
}

// characteristics
.group {
    strong {
        font-size: 0.85em;
        opacity: 0.5;
    }
    &:not(:first-child) {
        margin-top: 10px;
    }
}

.demolink {
    text-decoration: underline;
    font-size: 20px;
    font-weight: 600;
    color: $color-text;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

// Fixed Buy
.floatingBuy {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #171717f5;
    border-top: 1px solid #272727;

    .floatingBuyContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
    }
    .floatingBuyTitles {
        white-space: nowrap;
        .title {
            color: white;
            display: block;
            font-size: 20px;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
        }

        .discount {
            span:first-child {
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 5px;
                font-size: 14px;
                text-decoration: line-through;
                opacity: 0.8;
            }

            span:last-child {
                text-align: center;
                align-self: center;
                padding: 7px 10px;
                font-weight: 600;
                color: $color-text;
                white-space: nowrap;
                border-radius: $border-radius;
            }
        }
    }
}
.horsetrackLink {
    display: inline-block;
    margin-top: 9px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.38105;
    letter-spacing: 0.011em;
    text-shadow: 0 0 12px black;
}

.badge {
    width: fit-content;
    margin-bottom: 10px;
    margin-left: 0px;
}
.buttons {
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;

    > div {
        margin-top: 5px;
        margin-right: 10px;
    }

    @include mobile {
        padding: 0 20px;
    }

    .mobileButtons {
        @include tablet {
            display: none;
        }
    }

    .desktopButtons {
        @include mobile {
            margin-left: 0px;
            display: none;
        }
    }
}

.purple {
    color: $color-link;
}

.bold {
    font-weight: 800;
    margin-bottom: 20px;
    margin-top: 10px;
}

.documentation {
    margin-top: 50px;

    ul li {
        font-style: italic;
        list-style-type: circle;
    }
}

.asterisk {
    font-style: italic;
    font-size: 12px;
}

.smallText {
    font-style: italic;
    font-size: 15px;
    text-align: center;
}
.convertedPriceText {
    font-style: italic;
    font-size: 12px;
}

.feiBadge {
    margin-top: 100px;

    img {
        width: 30%;
        margin: auto;

        @include mobile {
            width: 60%;
        }

        @include tablet {
            width: 30%;
        }
    }

    div + div {
        margin-top: 15px;
    }
}

.explanationIntroModal {
    margin-top: 30px;
    font-size: 11px;
    text-align: center;
    font-style: italic;
}

.introTitle {
    margin-top: -30px;
}

.discount {
    margin-top: -80px;
    margin-bottom: -30px;
    @include mobile {
        margin-bottom: 10px;
        margin-top: -50px;
    }

    @include tablet {
        margin-top: -50px;
    }
}

.featuresList {
    li {
        margin-top: 10px;
    }
}
.featureTick {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 3px solid $color-success;
    border-right: 3px solid $color-success;
    margin-right: 10px;
}
.buttonsSubscription {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;

    > div {
        margin-top: 5px;
        margin-right: 10px;
    }

    .buttonSubscription {
        background: $color-subscription;
    }
}
