
















































































@import '@design';

.competitors {
    margin-top: 10px;
    li {
        display: flex;
        align-items: flex-start;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(black, 0.1);
        &:first-child {
            padding-top: 10px;
        }
        &:last-child {
            padding-bottom: 80px;
            border-bottom: 0;
        }
    }
    .competitorNumber {
        display: flex;
        justify-content: center;
        width: 30px;
        margin-top: 10px;
        margin-right: 10px;
        span {
            display: inline-block;
            padding: 0 5px;
            font-weight: 600;
            color: white;
            background: $color-purple;
            border-radius: $border-radius;
        }
    }
    .competitorContent {
        flex: 1;
        margin-bottom: 10px;
        line-height: 1.1;
        .competitorRider {
            font-size: 16px;
            font-weight: 600;
        }
        .competitorHorse {
            font-size: 0.95em;
            opacity: 0.8;
        }
    }
    .competitorDetails {
        display: flex;
        .detail {
            min-width: 50px;
            padding: 3px;
            text-align: center;
            background: rgba(black, 0.05);
            border-radius: $border-radius;
            &:not(:first-child) {
                margin-left: 10px;
            }
            .detailTitle {
                font-size: 10px;
                opacity: 0.8;
            }
            .detailContent {
                font-size: 12px;
            }
        }
    }
}
