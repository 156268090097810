



























































































































































































@import '@design';

.StripeElement--invalid {
    border-color: $color-pink !important;
}
.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
