









































































































































@import '@design';

.buttonDisappeared {
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
    transform: scale(0.5);
}

.spinner {
    padding: 5px;
}
