










































@import '@design';

.section {
    position: relative;
    padding: 140px 0 70px;

    @include tablet {
        padding: 180px 0 100px;
    }
    &.colorLight {
        background-color: white;
    }
}
.sectionContent {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-gap: 40px 0;
    gap: 40px 0;
    align-items: center;

    @include tablet {
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 100px;
        gap: 0 100px;
    }
    &.positionRight {
        direction: rtl;
    }
}
.sectionContent > * {
    direction: ltr;
}
.sectionTexts {
    p + p {
        margin: 16px 0 0;
    }
}
.sectionTitle {
    margin: 0 0 16px;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.4;
}
.sectionMedia {
    position: relative;
    z-index: 2;
}
