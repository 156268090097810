
































































































































































































































































































@import '@design';

.badge {
    width: 100px;
}

.hero {
    overflow-x: hidden;
}
.heroVideo {
    width: 100%;
    margin-top: 24px;
    border-radius: 16px;

    @include mobile {
        width: calc(100% + 20%);
        margin-left: -10%;
        border-radius: 0;
    }
}
.serviceLiveWebApp {
    width: 70%;
    margin: 0 auto;
}
.serviceLiveTracking {
    position: relative;
}
.serviceLiveTrackingImage {
    width: 300px;
    margin: 0 auto;
}

.technologiesObstacleMedia {
    width: 100%;

    @include tablet {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 800px;
    }
}
.technologiesObstacleMediaSecond {
    position: relative;
    width: 300px;
    margin-right: -526px;
}
.technologiesObstacleParagraph {
    width: 55%;
}
.technologiesTimeMedia {
    display: flex;
    align-items: center;
    justify-content: center;
}
.technologiesTimes {
    position: relative;
    font-family: $font-family;
    font-weight: 800;
}
.technologiesCurrentTime {
    width: 330px;
    font-size: 100px;
    line-height: 1;
    color: $color-text;

    @include mobile {
        margin-top: 50px;
        font-size: 80px;
    }
}
.technologiesIntermediateTimes {
    position: absolute;
    bottom: 110px;
    display: flex;
    flex-direction: column;

    @include mobile {
        bottom: 80px;
    }
}
.technologiesFirstIntermediateTime,
.technologiesSecondIntermediateTime {
    display: inline-block;
    padding: 3px 10px;
    font-size: 30px;
    border: 1px solid;
    border-radius: 6px;

    @include mobile {
        font-size: 20px;
    }
}
.technologiesFirstIntermediateTime {
    border-color: $color-error;
}
.technologiesSecondIntermediateTime {
    margin-bottom: 15px;
    border-color: $color-success;

    @include mobile {
        margin-bottom: 5px;
    }
}
.technologieWebAppLinks {
    margin-top: 20px;

    div:not(:first-child) {
        margin-top: 10px;
    }
}
.superpositionYTplaylist {
    margin-top: 20px;
}
.superpositionVideo {
    width: 100%;
    border-radius: $border-radius-large;
}

.transitionTimeEnterActive,
.transitionTimeLeaveActive {
    transition: all 0.6s ease;
}
.transitionTimeEnter,
.transitionTimeLeaveTo {
    opacity: 0;
    transform: translateY(100%);
}
