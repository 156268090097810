






@import '@design';

.wrapper {
    display: flex;
    justify-content: center;
    position: relative;
}
