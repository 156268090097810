












































































































































































































































































































@import '@design';

.wrapper {
    padding-top: 45px;
    line-height: 30px;
    border-top: 1px solid $color-background-dark-soft;
    a {
        color: $color-text;
        text-decoration: none;
        transition: color 0.1s;
    }
}
.footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.links {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-top: 20px;

    @include desktop {
        margin-top: 0;
    }
}
.linksTitle {
    margin: 0 0 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
}
.optionList {
    display: flex;
    flex-wrap: wrap;
    li {
        width: 100%;
    }
    a {
        @include mobile {
            padding: 0 10px;
        }

        display: block;
        padding: 0 15px;
        overflow: hidden;
        line-height: 36px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 4px;
        transition: color 0.1s, background-color 0.1s;
        &:hover {
            color: $color-text;
            background-color: $color-background-dark-soft;
        }
    }
    .flag {
        margin-right: 12px;
    }
    .selected {
        font-weight: 600;
        color: black;
        background: white;
        &:hover > span::before {
            background: url('~@assets/vector/checkmark-white.svg');
        }
    }
    .selected > span::before {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 0 6px 0 -2px;
        vertical-align: -2px;
        content: '';
        background: url('~@assets/vector/checkmark-black.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.metaNav {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow: 2;
    margin-bottom: 20px;

    @include tablet {
        flex-basis: auto;
        margin: 0 0 20px;
    }
}
.metaNav > li {
    margin-right: 20px;
}
.column {
    @include mobile {
        width: 100%;
    }

    flex-basis: auto;
    margin-bottom: 20px;
}
.select {
    z-index: 499;
    svg path {
        fill: currentColor;
    }
}
.countryPicker {
    padding: 25px;

    @include mobile {
        padding: 10px;
    }
    .optionList {
        li {
            width: 50%;

            @include desktop {
                width: 25%;
            }
        }
    }
}
.languagePicker {
    padding: 10px 5px;
}
.popup {
    position: absolute;
    bottom: 40px;
    left: -5px;
    z-index: 1000;
    font-size: 15px;
    line-height: 26px;
    pointer-events: none;
    background: $color-background-dark;
    border: 1px solid $color-background-dark-soft;
    border-radius: $border-radius;
    opacity: 0;
    transition-duration: 0.25s;
    transition-property: transform, opacity;
    transform: rotate3d(1, 1, 0, 15deg);
    transform-origin: 0 100%;
    will-change: transform, opacity;
    &::before {
        position: absolute;
        bottom: -11px;
        left: 30px;
        width: 20px;
        height: 20px;
        content: '';
        background: $color-background-dark;
        border-color: $color-background-dark-soft;
        border-style: solid;
        border-width: 0 1px 1px 0;
        border-radius: 20px 0 3px 0;
        transform: rotate(45deg);
    }
}
.popupActive .popup {
    pointer-events: auto;
    opacity: 1;
    transform: none;
}
h4.popupTitle {
    display: block;
    padding: 0 15px;
    margin-bottom: 5px;
    font-family: $font-family-heading;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
    color: $color-text;

    @include mobile {
        padding: 0 10px;
    }
}
.country {
    margin-bottom: 5px;
    .popup {
        @include mobile {
            width: calc(100vw - 30px);
        }

        @include tablet {
            width: 550px;
        }

        @include desktop {
            width: 850px;
        }
    }
}
.select {
    position: relative;
    perspective: 2000px;
    z-index: 499;
}
.rootLink {
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    @include unselectable;
    &:hover {
        color: rgba(white, 0.5);
    }
    svg {
        display: block;
        margin-right: 5px;
    }
}
.copyright {
    padding: 30px 0;
    margin-top: 10px;
    margin-right: 0;
    color: rgba($color-text, 0.6);
    text-align: center;
}
.socialNetworks {
    display: flex;
    margin-top: 30px;
    img {
        margin-right: 10px;
    }
}
.newsletter {
    font-weight: 600;
}
