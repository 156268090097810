









































.texts {
    margin-bottom: 30px;
    margin-top: 20px;

    div + div {
        margin-top: 15px;
    }
}

.img {
    border-radius: 10px;
    width: 100%;
}

.title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 700;
}
