






























































































































































































































@import '@design';

.wrapper {
    position: relative;
    height: 368px;
}

.svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 368px;
}
.svgPath {
    circle {
        fill: #3e3e3e;
        stroke: #121216;
        stroke-width: 3;
        animation: circleBorn 0.3s ease-in-out;
    }
}

@keyframes circleBorn {
    0% {
        r: 0;
    }
    70% {
        r: 17;
    }
    100% {
        r: 13;
    }
}

// Path
.path,
.pathPassed {
    fill: none;
    stroke-width: 1;
}
.path {
    stroke: #3e3e3e;
}
.pathPassed {
    stroke: #121217;
    transition: all 8s linear;
}
.pathPoint {
    fill: white;
}

// Boards
.board {
    @include unselectable;

    position: absolute;
    z-index: 1;
    padding: 5px 7px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: black;
    text-transform: uppercase;
    background: white;
    background: linear-gradient(-25deg, #c4987a 33%, #ffd5bf 73%);
    border-radius: $border-radius;
    transform: translate(-50%, -50%) scale(1);
    animation: divBorn 0.3s ease-in-out;
    &.boardFinish {
        color: #c4987a;
        background: black;
        border: 1px solid #c4987a;
    }
}

@keyframes divBorn {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    70% {
        transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

// Obstacles
.obstacles {
    position: absolute;
    z-index: 1;
    height: 50px;
    transform: translate(-50%, -50%) scale(1);
    animation: divBorn 0.3s ease-in-out;
    .obstacle {
        width: 100%;
        height: 100%;
        transform: translate(0, 0);
    }
    .palanques {
        display: flex;
        justify-content: center;
        .jump {
            @include jump;

            position: relative;
            z-index: 1;
            width: 3px;
            height: 100%;
            background: white;
            border-radius: 0;
        }
    }
    .wall {
        display: flex;
        justify-content: center;
        .jump {
            @include jump;

            position: relative;
            z-index: 1;
            width: 8px;
            height: 100%;
            border-radius: 0;
        }
    }
    .vertical {
        display: flex;
        justify-content: center;
        .jump {
            position: relative;
            z-index: 1;
            height: 100%;

            @include jump;
        }
    }
    .oxer {
        .jump {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .jump > div {
            @include jump;
        }
    }
    .triple {
        .jump {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .jump > div {
            @include jump;
        }
    }
    .oxerPolonais {
        .jump {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .jump > div {
            @include jump;
            &:first-child {
                transform: rotate(15deg) translateX(5px);
            }
            &:last-child {
                transform: rotate(-15deg) translateX(-5px);
            }
        }
    }
    .watter {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        width: calc(100% + 20px);
        height: 80%;
        margin: auto;
        background: rgba(0, 38, 255, 0.35);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }
}
.obstacleNumber {
    @include unselectable;

    position: absolute;
    z-index: 2;
    padding: 2px 5px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    color: white;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    background: rgba(#121216, 0.85);
    border: 1px solid rgba(white, 0.2);
    border-radius: 4px;
    transform: translate(40%, -50%) scale(1);
    animation: numberBorn 0.3s ease-in-out;
}

@keyframes numberBorn {
    0% {
        transform: translate(40%, -50%) scale(0);
    }
    70% {
        transform: translate(40%, -50%) scale(1.2);
    }
    100% {
        transform: translate(40%, -50%) scale(1);
    }
}
