


























.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    min-height: auto;
    overflow-x: hidden;
    background-color: #000;
    transform: skewY(-12deg);
}
