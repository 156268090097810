





































@import '@design';

.paragraph {
    flex: none;
    position: relative;
    z-index: 1;
}
.sizeFull {
    width: 100%;
}
.sizeBig {
    width: 75%;

    @include mobile {
        width: 100%;
    }
    @include tablet-only {
        width: 83.33333%;
    }
}
.sizeHalf {
    width: 42%;

    @include mobile {
        width: 100%;
    }
}
.sizeThird {
    width: 58%;
}
.positionLeft {
    text-align: left;
}
.positionCenter {
    text-align: center;
}
.positionRight {
    text-align: right;
}
