






@import '@design';

.subsection {
    margin-top: 92px;
    @include mobile {
        margin-top: 33px;
    }
    @include tablet-only {
        margin-top: 46px;
    }
}
