






































@import '@design';

.title {
    margin-bottom: 3px;
    font-size: 24px;
    font-weight: 600;
}
.subtitle {
    font-size: 14px;
    strong {
        display: inline-block;
        font-weight: 600;
    }
    del {
        font-weight: 400;
        text-decoration: line-through;
    }
}
.separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid hsla(0, 0%, 18%, 1);
    &.separatorHighlight {
        border-color: $color-link;
    }
}
.button {
    margin-top: 24px;
}
.footer {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33em;
    color: hsla(0, 0%, 40%, 1);
    text-align: center;
    word-wrap: break-word;
    strong {
        @include font-heading;

        font-weight: 800;
    }
}
.positions {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 20px;
    .position {
        width: 7px;
        height: 7px;
        background: $color-background-dark-extra-soft;
        border-radius: $border-radius-round;
        &:not(:first-child) {
            margin-left: 5px;
        }
    }
    .positionActive {
        background: $color-link;
    }
}
