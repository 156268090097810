


























@import '@design';

.arrow {
    position: absolute;
    z-index: 3;
    padding: 10px;
    &::after {
        position: relative;
        display: block;
        padding: 3px;
        content: '';
        border: solid currentColor;
        border-width: 0 2px 2px 0;
        transition: all 0.2s;
        transform: rotate(45deg);
        transform-origin: center;
    }
    &.arrowSizeMedium::after {
        padding: 5px;
    }
    &.arrowSizeLarge::after {
        padding: 10px;
    }
    &.arrowOpen::after {
        top: 3px;
        transform: rotate(-135deg);
    }
}
