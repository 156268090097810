.subsection {
    position: relative;
    z-index: 2;
    margin-top: 44px;
}
.sectionLighten {
    background: rgba(255, 255, 255, 0.075);
}
.sectionContent {
    max-width: 980px;
    padding-top: 125px;
    padding-bottom: 125px;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
        width: 87.5%;
        padding-top: 62px;
        padding-bottom: 75px;
    }
}
.sectionHero {
    padding-top: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    .sectionContent {
        padding-top: 108px;
        padding-bottom: 125px;
    }
}
