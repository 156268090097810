































@import '@design';

.appStores {
    position: relative;

    @include mobile {
        margin-top: 20px;
    }

    img {
        display: inline-flex;

        &:nth-child(1) {
            margin-left: 10px;
        }
    }
    &.footer {
        .apple {
            width: 100px;
        }
        .google {
            width: 106px;
        }
    }
    &.noFooter {
        .apple {
            width: 130px;
        }
        .google {
            width: 137px;
        }
    }
}
