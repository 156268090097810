






















@import '@design';

.sectionIntro {
    text-align: center;
}
.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: $color-link;
    border-radius: 50%;
    svg {
        width: 48px;
        height: 48px;
    }
}
