





































@import '@design';

.wrapper {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
    transform: translateY(calc(-100% - 8px));
    &.hasMessages {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}
.success {
    margin-top: 8px;
    font-size: 13px;
    color: $color-success;
    text-align: left;
}
