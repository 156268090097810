






















































@import '@design';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    @include tablet {
        overflow-y: auto;
    }

    @include mobile {
        overflow-y: hidden;
        pointer-events: none;
        background: none;
        transform: translate3d(0, 0, 0);
    }
    &.overlay {
        @include tablet {
            background: rgba(black, 0.6);
        }

        @include mobile {
            display: none;
        }
    }
}
.table {
    @include tablet {
        display: table;
        width: 100%;
        height: 100%;
    }

    @include mobile {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        pointer-events: auto;
        will-change: transform;
    }
}
.cell {
    @include overflow-touch;

    @include tablet {
        display: table-cell;
        padding: 64px;
        vertical-align: middle;
    }

    @include mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        background-color: $color-background-dark;
    }
}
.modal {
    width: 100%;
    margin: auto;

    @include tablet {
        max-width: 450px;
        position: relative;
        padding: 45px;
        background-color: $color-background-dark;
        border-radius: $border-radius;
        box-shadow: $box-shadow-popup;
    }

    @include mobile {
        height: 100%;
        padding: 20px;
    }
}
.modalClose {
    margin-bottom: 24px;
    .modalCloseButton {
        display: inline-block;
        padding: 21px;
        margin: -24px;
        cursor: pointer;
    }
    .modalCloseCross {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        font-size: 0;
        cursor: pointer;
        &::after,
        &::before {
            position: absolute;
            top: 11px;
            right: 0;
            left: 0;
            height: 2px;
            content: '';
            background: currentColor;
            border-radius: 3px;
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}
.transitionSlideEnterActive,
.transitionSlideLeaveActive {
    transition: all 0.3s ease;
}
.transitionSlideEnter,
.transitionSlideLeaveTo {
    transform: translate3d(0, 100%, 0);
}
.transitionOpacityEnterActive,
.transitionOpacityLeaveActive {
    transition: all 0.6s ease;
}
.transitionOpacityEnter,
.transitionOpacityLeaveTo {
    opacity: 0;
}
