






@import '@design';

.table {
    margin-top: 50px;

    @include mobile {
        margin-top: 86px;
    }
}
.group {
    display: flex;
    strong {
        margin-right: 5px;
    }
    &:not(:last-child) {
        margin-bottom: 3px;
    }
}
